import '../styles/index.scss';
import React, { Component } from "react";
import SEO from "../components/seo";
import Hero from '../components/hero';
import AboutComplex from '../components/about';
// import Apartments from '../components/apartments';
// import Gallery from '../components/gallery';
import Footer from '../components/footer';
import { graphql } from 'gatsby';

class IndexPage extends Component {
  componentDidMount() {
    const bodyOverflow = getComputedStyle(document.documentElement).overflow;
    if (bodyOverflow === 'hidden') document.documentElement.style.overflow = 'visible';
  }

  render() {
    return (
      <div ref={this.pageRef} className="index-page page">
        <SEO title="Главная" />

        <Hero
          heading="ИДЕАЛЬНОЕ МЕСТО ДЛЯ ЖИЗНИ И ОТДЫХА"
          bgImg={this.props.data.bg.childImageSharp.fluid}
          bigBtnText="выбрать таунхаусы"
          bigBtnLink="/townhouses"
          bigBtnText2="выбрать квартиры"
          bigBtnLink2="/flats"
          showVideoBtn
        />
        <AboutComplex />
        {/* <Apartments /> */}
        {/* <Gallery /> */}
        <Footer />
    </div>
    )
  }
}

export default IndexPage;

export const pageQuery = graphql`
  query {
    bg: file(relativePath: { eq: "bg/hero_bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
